* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-x pan-y;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  touch-action: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img,
svg {
  display: block;
  user-select: none; /* Prevents text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
  pointer-events: none;
}

.App {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#scan {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.75;
}

#cameraFeed {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: none;
  border: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

.permissionsPopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  font-size: 6.171vw;
  .popup {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "PatrickHand";
    color: white;
    width: 13.969em;
    height: 7.72em;
    border-radius: 0.555em;
    background: linear-gradient(
      211deg,
      #33accb -36.92%,
      #33a8c9 -35.12%,
      #3775b2 -2.72%,
      #3a4ba0 29.68%,
      #3c2a91 62.08%,
      #3e1287 90.88%,
      #3f0481 119.69%,
      #40007f 143.09%
    );
    box-shadow: 0px 0.111em 0.111em 0px rgba(0, 0, 0, 0.25);
    p {
      margin-top: 1.138em;
    }
    button {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.777em;
      border-radius: 0px 0px 0.555em 0.555em;
      background-color: rgba(255, 255, 255, 0.85);
      span {
        font-size: 1.777em;
        background: linear-gradient(
          211deg,
          #33accb -36.92%,
          #33a8c9 -35.12%,
          #3775b2 -2.72%,
          #3a4ba0 29.68%,
          #3c2a91 62.08%,
          #3e1287 90.88%,
          #3f0481 119.69%,
          #40007f 143.09%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  @media (min-aspect-ratio: 550 / 400) {
    font-size: 9vh;
    font-size: 9dvh;
  }
}

.canvasWrapper {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  pointer-events: all !important;
  overflow: hidden;
}

.nonInteractive {
  pointer-events: none !important;
  * {
    pointer-events: none !important;
  }
  button {
    pointer-events: all !important;
  }
}

.sceneBtn {
  img {
    width: 250px;
  }
}

.portal {
  animation: portalAnimation 2s linear infinite;
  @keyframes portalAnimation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
}

.label,
.sceneBtn {
  transition: opacity 0.5s ease;
  &.hide {
    * {
      pointer-events: none !important;
    }
    opacity: 0;
  }
  &.show {
    opacity: 1;
    pointer-events: none !important;
    * {
      pointer-events: all;
    }
  }
}

.loadingScreen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  iframe {
    border: none;
    height: 50vw;
    pointer-events: none;
    touch-action: pan-x pan-y;
    * {
      pointer-events: none;
      touch-action: pan-x pan-y;
    }
  }
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  @media (min-aspect-ratio: 1 / 1) {
    iframe {
      height: 50vh;
      height: 50dvh;
    }
    @media (min-aspect-ratio: 550 / 400) {
      iframe {
        height: 60vh;
        height: 60dvh;
      }
    }
  }
}

.scanBtn {
  position: absolute;
  font-size: 4vw;
  left: calc(env(safe-area-inset-left, 0) + 0.5em);
  top: 0.5em;
  z-index: 10;

  img {
    width: 6em;
  }
  @media (min-aspect-ratio: 1 / 1) {
    font-size: 4dvh;
  }
}

.scanningUI {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 100vw;
  gap: 0.05em;
  z-index: 1;
  button {
    img {
      width: 0.159em;
    }
  }
  img {
    width: 0.945em;
  }
  div {
    color: white;
    font-size: 0.0389em;
    font-family: "ComfortaaBold";
    text-shadow: 0.06em 0.06em 0.1em black, -0.06em -0.06em 0.1em black;
    font-weight: 700;
    line-height: normal;
  }
  @media (min-aspect-ratio: 375 / 540) {
    font-size: 68dvh;
  }
}

.targetImagesLoader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 100vw;
  z-index: 1;
  pointer-events: none;
  div {
    color: white;
    font-size: 0.05em;
    font-family: "ComfortaaBold";
    text-shadow: 0.06em 0.06em 0.1em black, -0.06em -0.06em 0.1em black;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  @media (min-aspect-ratio: 375 / 540) {
    font-size: 68dvh;
  }
}

.pulse {
  animation: pulsing 2.5s ease-in-out infinite;
  @keyframes pulsing {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.scanningHelper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 100vw;
  gap: 0.05em;
  z-index: 1;
  background: rgba(0, 0, 0, 0.65);
  .pageSelector {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > span {
      font-size: 0.0519534em;
      color: white;
      font-family: "ComfortaaBold";
    }
    .buttons {
      background-color: #d9d9d9;
      display: flex;
      width: 0.211em;
      border-radius: 0.007751em;
      height: 0.0534883em;
      margin-top: 0.012046em;
      button {
        padding-top: 0.001em;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 30%;
        }
      }
      span {
        padding-top: 0.05em;
        background-color: white;
        width: 2.189em;
        height: 100%;
        color: #676363;
        font-size: 0.0485093em;
        font-family: "ComfortaaBold";
      }
    }
  }
  img {
    width: 0.945em;
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    gap: 0.058139em;
    button {
      width: 0.389922em;
      height: 0.156589em;
      border-radius: 0.023255em;
      background: linear-gradient(180deg, #33accb 0%, #40007f 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "PatrickHand";
      span {
        font-size: 0.044015em;
        color: white;
        margin-bottom: 0.1em;
      }
    }
  }
  @media (min-aspect-ratio: 375 / 540) {
    font-size: 68dvh;
  }
}

.jumping {
  animation: jumping 1.5s ease infinite;
  @keyframes jumping {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5%);
    }
    100% {
      transform: translateY(0);
    }
  }
}

#ar-viewport {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  // left: 0 !important;
  // top: 0 !important;
  // width: 100% !important;
  // height: 100% !important;
}

#ar-viewport-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  pointer-events: none;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
}

.shermanFalling {
  &.show {
    img {
      animation: shermanFalling 1.5s ease-in forwards;
    }
  }

  @keyframes shermanFalling {
    0% {
      transform: scale(4) translate(0, 0);
    }
    60% {
      transform: scale(4) translate(-10%, 160%);
    }
    80% {
      transform: scale(4) translate(-10%, 130%);
    }
    90% {
      transform: scale(4) translate(-10%, 160%);
    }
    95% {
      transform: scale(4) translate(-10%, 155%);
    }
    100% {
      transform: scale(4) translate(-10%, 160%);
    }
  }
}

.wakeUp {
  animation: wakeUp 2.5s 1s ease forwards;
  @keyframes wakeUp {
    0% {
      transform: scale(7) translateX(0);
    }
    20% {
      transform: scale(7) translateX(-50%);
    }
    35% {
      transform: scale(7) translateX(50%);
    }
    45% {
      transform: scale(7) translateX(-50%);
    }
    52% {
      transform: scale(7) translateX(50%);
    }
    60% {
      transform: scale(7) translateX(-50%);
    }
    67% {
      transform: scale(7) translateX(50%);
    }
    75% {
      transform: scale(7) translateX(-50%);
    }
    83% {
      transform: scale(7) translateX(50%);
    }
    90% {
      transform: scale(7) translateX(-50%);
    }
    100% {
      transform: scale(7) translateX(0);
    }
  }
}

.planeFlying {
  animation: planeFlying 3s linear forwards,
    planeFlyingFadeOut 3s linear forwards;
  @keyframes planeFlying {
    0% {
      transform: scale(7) translate(0, 0);
    }
    100% {
      transform: scale(10) translate(-600%, 50%);
    }
  }
  @keyframes planeFlyingFadeOut {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@font-face {
  font-family: "PatrickHand";
  src: url("https://magic-portal-v1.s3.us-east-2.amazonaws.com/fonts/PatrickHand-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "ComfortaaBold";
  src: url("https://magic-portal-v1.s3.us-east-2.amazonaws.com/fonts/Comfortaa-Bold.woff2")
    format("woff2");
}
